<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  item_cartel.cartl_name }}</h2>
						<span class="page_subtit ">{{  item_board_config.board_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 게시글 -->
		<div class="post_wrap pt-70 pb-50">
			<div class="container">
				<article class="post_list_item_view">
					<!-- 게시글 작성자 -->
					<div class="justify-space-between  ptb-10 pl-20 size-px-14 items-center">
						<div class="">
							<template
								v-if="is_notice"
							>
								<h2 class="" >{{ item_bbs.post_title }}</h2>
							</template>

							<a
								v-else

								@click="is_098 = true"
							>
								<span class="profile_inner">
									<img
										v-if="item_bbs.post_member_profle_nft_card_img_url"
										:src="$request.upload_url(item_bbs.post_member_profle_nft_card_img_url)" alt="No Picture"
									>
									<img
										v-else
										:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
									>
								</span>
								<strong class="name">{{ item_bbs.post_member_nickname }}</strong>
							</a>
						</div>
						<div class="justify-space-between items-center">
							<span class="time mr-5">{{ getTimeStory('item_bbs', item_bbs.post_registdate)}}</span>
							<button
								@click="is_more = true"
								class="post_set mr-10"
							><v-icon>mdi-dots-vertical</v-icon></button>
						</div>
					</div>
					<!-- //게시글 작성자 -->
					<!-- 게시글 메인영역 -->



					<div class="photo_view">
						<!-- 이미지 -->
						<div class="photo_collage_view">
							<div class="image">
								<CartelBbsFile
									v-if="files.length > 0"
									:user="user"
									:item="item_bbs"
									:index="0"

									class="post_main_view mt-5"
								></CartelBbsFile>
							</div>
						</div>
					</div>

					<div
						v-if="false && files.length > 0"
						class="post_main_view mt-5"
					>
						<div class="photo_view">
							<!-- 이미지 -->
							<div class="photo_collage_view">
								<div class="image">
									<carousel
										:perPage="1"
									>
										<template
											v-for="(file, f_index) in files"
										>
											<slide
												v-if="file"
												:key="'_video_' + f_index"
												style="display: flex; flex-direction: column; justify-content: center; "
											>
												<img
													v-if="file.post_appendix_file_div_code.indexOf('CA01000001') > -1"
													:src="$request.upload_url(file.post_appendix_file_org_url)" alt=""
													width="100%"
												>
												<video
													v-else-if="file.post_appendix_file_div_code.indexOf('CA01000002') > -1"
													width="100%"
													controls
												>
													<source
														:src="file.post_appendix_file_org_url"
													>
												</video>

												<div
													v-if="file.post_appendix_file_div_code.indexOf('CA01000003') > -1"
													class="text_view" style="position: static !important;"
												>
													<figure class="audio_wrap">
														<div class="audio_bg">
															<span class="hide">{{ $language.common.soundBackgroundImg }}</span>
														</div>
														<video
															width="100%"
															controls
															style="position: absolute; bottom: 0"
														>
															<source
																:src="file.post_appendix_file_org_url"
															>
														</video>
													</figure>
												</div>
											</slide>
										</template>
									</carousel>
								</div>
							</div>
							<!-- //이미지 -->
						</div>
					</div>

					<div class="post_main_view mt-30">
						<!-- 비주얼 영역 -->
						<!-- //비주얼 영역 -->
						<div class="text_view original_text_view">
							<p class="text_area original_text" style="white-space: pre-line; word-break: break-all;">{{ item_bbs.post_contents }}</p>
						</div>
					</div>
					<!-- //게시글 메인영역 -->

					<mafia_vote
						v-if="item_bbs.vote_fg == 'Y'"
						:cartel="item_cartel"
						:vote="item_bbs.vote"
						:bbs="item_bbs"
						:user="user"

						type="vote"
						@click="item_bbs.vote_fg = 'N'; getBbs()"
					></mafia_vote>

					<!-- 공감하기 -->
					<mafia_emoji
						v-if="item_bbs.bulletin_number"
						:cartel="item_cartel"
						:bbs="item_bbs"
						:user="user"
						:list="item_bbs.sympaty_list"

					></mafia_emoji>
					<!-- //공감하기 -->

					<!-- 댓글영역 -->
					<div
						v-if="item_bbs.comment_writing_fg != 'N'"
						class="post_comment_main_view "
					>
						<span class="comment_count">{{ $language.common.comment }} <em class="count">{{  items_comment.length }}</em></span>
						<!-- 댓글 -->
						<div
							v-for="(comment, index) in list_comment"
							:key="'comment_' + index"
							class="comment_layout"
						>
							<template
								v-if="comment.comment_level == 0"
							>
							<div
								v-if=comment.is_delete
								class="comment_view"
							>
								<div class="item_wrap">
									<div class="write_user">
										<a class="u_profile">
											<img
												:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
											>
										</a>
										<div class="write_desc">
											<strong class="name">{{  $language.common.text_delete_comment }}</strong>
										</div>
									</div>
									<div class="comment_body">
										<p style="word-break:break-all;"></p>

									</div>
								</div>
							</div>
							<div
								v-else
								class="comment_view"
							>
								<div class="item_wrap">
									<div class="write_user">
										<a class="u_profile">
											<img
												v-if="comment.comment_member_profle_nft_card_img_url"
												:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="No Picture"
											>
											<img
												v-else
												:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
											>
										</a>
										<div class="write_desc">
											<strong class="name"><em v-if="comment.is_block" class="state-block">{{ $language.common.cut_off }}</em> {{  comment.comment_member_nickname }}</strong>
										</div>
									</div>
									<div class="comment_body">
										<p :inner-html.prop="comment.comment | nl2br" style="word-break:break-all;"></p>
										<img
											v-if="comment.comment_img_url"
											:src="$request.upload_url(comment.comment_img_url)"
											style="width: 100%; margin-top: 10px"
										/>
									</div>
									<div class="write_info">
										<span class="time">{{  getTimeStory('comment', comment.registdate) }}</span>
										<button
											v-if="false"
											class="reply"  @click="$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: $route.params.bbs_id}})"
										>{{ $language.common.write_reply }}</button>
									</div>
								</div>
							</div>
							</template>
							<!-- 재댓글 -->
							<div
								v-else
								class="replylist_view"
							>
								<div
									class="item_wrap"
								>
									<template
										v-if="comment.is_delete"
									>
										<div class="write_user">
											<a class="u_profile">
												<img
													:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
												>
											</a>
											<div class="write_desc">
												<strong class="name" >{{  $language.common.text_delete_comment }}</strong>
											</div>
										</div>
									</template>
									<template
										v-else
									>
										<div class="write_user">
											<a class="u_profile">
												<img
													:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="No Picture"
													@error="$bus.$emit('onErrorImage', $event)"
												>
											</a>
											<div class="write_desc">
												<strong class="name" ><em v-if="comment.is_block" class="state-block">{{ $language.common.cut_off }}</em>  {{  comment.comment_member_nickname }}</strong>
											</div>
										</div>
										<div class="comment_body" style="position: relative">
											<button class="hashtag" style="position: absolute; top: 0px">&#64;{{ comment.comment_member_nickname }}</button>
											<p style="">&#64;{{ comment.comment_member_nickname}} {{ comment.comment }}</p>
											<img
												:src="$request.upload_url(comment.comment_img_url)"
												style="width: 100%; margin-top: 10px"
												@error="$bus.$emit('onErrorImage', $event)"
											/>
										</div>
										<div class="write_info">
											<span class="time">{{  getTimeStory('reply', comment.registdate) }}</span>
											<button

												v-if="false"
												class="reply" @click="$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: $route.params.bbs_id, reply_id: comment.comment_number}})"
											>{{ $language.common.write_reply }}</button>
										</div>
									</template>
								</div>
							</div>
							<!-- //재댓글 -->
						</div>
						<!-- 댓글 -->
					</div>
					<!-- //댓글영역 -->
				</article>

			</div>
		</div>
		<!-- //게시글 -->
		<!-- 댓글쓰기 -->
		<div
			v-if="item_bbs.comment_writing_fg != 'N'"
			class="comment_write"
		>
			<div
				v-if="item_comment.img_src"
				class="photo_area"
			>
				<div class="photo_area_view">
					<img
						v-if="item_comment.img_src"
						:src="$request.upload_url(item_comment.img_src)"
					/>
				</div>
				<button
					@click="item_comment.img_src = ''"
					class="view_close">
					<i class="icon-close"></i>
				</button>
			</div>
			<div
				@click="toComment"
				class="write_area"
			>
				<div class="container">
					<h3 class="hide">{{ $language.common.comment_require }}</h3>
					<div class="write_main">
						<div class="btn_upload">
							<label for="albumFile" class="comment_albumfile" aria-label="Upload Image"></label>
						</div>
						<div class="mentions">
							<button
								v-if="item_comment.upper_id"
								class="hashtag"
							>&#64;{{ item_comment.upper_nick }} <v-icon small @click="clearReply">mdi-close</v-icon></button>
							<textarea
								v-model="item_comment.content"
								cols="20" :placeholder="$language.common.comment_require"
								:rows="rows" @focus="rows = 3" @focusout="rows=1"
							></textarea>

						</div>
						<div class="submit_wrap">
							<button
								type="submit" class="write_submit"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //댓글쓰기 -->

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_bbs"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="mount"
			@deleteBbs="deleteBbs"
			@modify="toModify"

			:user="user"
			:item_bbs="item_bbs"
			style="z-index: 1003"
		>
		</mafia064>
	</div>
</template>

<script>
	import mafia_vote from '@/view/Cartel/mafiaVote'

	import { Carousel, Slide } from 'vue-carousel';
	import mafia_emoji from '@/view/Cartel/mafiaEmoji'
	import mafia064 from "@/view/Cartel/mafia064";
	import mafia098 from '@/view/Cartel/mafia098'
	import CartelBbsFile from "@/view/Cartel/CartelBbsFile";

	export default {
		name: 'mafia058'
		, props: ['user']
		, components: {CartelBbsFile, mafia064, mafia_vote, mafia_emoji, Carousel, Slide, mafia098}
		, data: function(){
			return {
				program: {
					name: this.$language.notice.article_detail
					, title: this.$language.notice.article_detail
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
					, from: this.$route.params.from
				}
				, item_cartel: {}
				, item_board_config: {}
				, item_bbs: {
					post_registdate: ''
					, vote_fg: 'N'
				}
				, items_comment: []
				, rows: 1
				, item_comment: {
					cartel_id: this.$route.params.idx
					, bbs_code: this.$route.params.code
					, bbs_id: this.$route.params.bbs_id
					, user_id: this.user.id
					, user_nick: this.user.nick
					, user_img_src: this.user.img_src
					, upper_id: ''
					, upper_nick: ''
					, content: ''
				}
				, is_emoji: false
				, is_more: false
				, is_098: false
				, item_search: this.$storage.init({
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				})
			}
		}
		, computed: {
			text_title: function(){

				let t = this.item_bbs.nick

				if(this.$route.params.code == 'CA00700001'){
					t = this.item_bbs.title
				}

				return t
			}
			, is_notice: function(){
				let t = false
				if(this.$route.params.code == 'CA00700001'){
					t = true
				}
				return t
			}
			, video: function(){
				let t = []
				if(this.item_bbs.video){
					if(this.item_bbs.video.length > 0){
						t = this.item_bbs.video
					}
				}
				return t

			}
			, files: function(){
				let t = []
				if(this.item_bbs.post_file_list){
					if(this.item_bbs.post_file_list.length > 0){
						t = this.item_bbs.post_file_list
					}
				}
				return t

			}
			, sound: function(){
				let t = []
				if(this.item_bbs.sound){
					if(this.item_bbs.sound.length > 0){
						t = this.item_bbs.sound
					}
				}
				return t

			}
			, is_friend: function(){
				let t = false

				return t
			}
			, list_comment: function(){
				return this.items_comment.filter(function(item){
					if(item.blok_member_fg == 'Y'){
						item.is_block = true
					}
					return item
				})
			}
		}
		, methods: {

			getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					let url = this.$api_url.api_path.get_open_cartel_info
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_info
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getBoardConfig: async function(){
				try{
					this.$bus.$emit('on', true)
					let url = this.$api_url.api_path.get_open_board_config
					if(this.user.member_number){
						url = this.$api_url.api_path.get_board_config
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})

					if(result.success){
						this.item_board_config = result.data
						await this.getBbs()
						// await this.getBbsComment()
					}else{
						if(result.code == 'E001201021'){
							this.$bus.$emit('notify', { type: 'error', message: result.message})
							this.$router.back()
						}
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getBbs: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_cartel_bbs_info
					if(this.user.member_number){
						url = this.$api_url.api_path.get_cartel_bbs_info
					}
					if(this.$route.params.code == 'CA00700001'){
						url = this.$api_url.api_path.get_open_cartel_notice_info
						if(this.user.member_number){
							url = this.$api_url.api_path.get_cartel_notice_info
						}
					}

					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
						}
						, type: true
					})

					if(result.success){
						this.item_bbs = result.data
						this.$set(this.item_bbs, 'board_number', this.$route.params.b_id)
						this.item_bbs.vote = result.data.vote_info
						this.item_bbs.vote.vote_participation_people_count = result.data.vote_participation_people_count
						this.item_bbs.vote.time = result.data.vote_info.vote_end_daytime
						this.item_bbs.vote.items = result.data.vote_list

						this.items_comment = result.data.comment_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBbsComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_bbs_comment_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_comment = result.data.comment_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postComment: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw this.$language.common.comment_require_02
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_comment
						, data: new Object(this.item_comment)
						, name: 'postComment'
					})

					if(result.success){
						if(this.item_comment.upper_id){
							for(let c of this.items_comment){
								if(c.id == this.item_comment.upper_id){
									console.log('c', c)
									if(!c.reply){
										c.reply = []
									}
									c.reply.push(result.data)
								}
							}
						}else{
							this.items_comment.unshift(result.data)
						}
						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code
							, bbs_id: this.$route.params.bbs_id
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_id: ''
							, upper_nick: ''
							, content: ''
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, clearReply: function(){
				this.item_comment.upper_id = ''
				this.item_comment.upper_nick = ''
			}
			, setReply: function(comment){
				this.item_comment.upper_id = comment.upper_id
				this.item_comment.upper_nick = comment.user_nick
				//this.item_comment.content = '@' + comment.user_nick + ' '
			}
			, getTimeStory: function(type, created_at){
				console.log(type, created_at)
				return this.$date.getTimeStory(created_at)
			}
			, setFile: function(e){
				console.log(e)

				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.$set(self.item_comment, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e.target.files[0])
			}
			, mount: async function(){

				await this.getCartel()
				await this.getBoardConfig()

				this.is_more = false
			}

			, deleteBbs: async function(){
				this.$emit('goBack')
			}
			, goBack: function(){
				if(this.$route.params.code == 'CA00700001'){
					this.$bus.$emit('to', {name: 'mafia127', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
				}else{
					this.$emit('goBack')
				}
			}
			, toComment: function(){
				this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: this.$route.params.code, bbs_id: this.$route.params.bbs_id}})
			}
			, toModify: function(){
				this.$bus.$emit('to', {name: 'mafia1272', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
			}
		}
		,async created() {

			this.$bus.$emit('onLoad', this.program)
			await this.mount()
		}
		, watch: {
			'item_comment.content':{
				deep: true
				, handler: function(call){
					console.log('call', call)
					/*
					if(this.item_comment.upper_id){

						if(this.item_comment.upper_nick != call.slice(1, this.item_comment.upper_nick.length + 1)){
							this.item_comment.upper_id = ''
							this.item_comment.upper_nick = ''
						}
					}else{
						this.item_comment.upper_nick = ''
					}
					*/
				}
			}
		}
	}
</script>

<style>
.hashtag { font-weight: normal !important;}
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }

.bright_radio input[type="checkbox"] { overflow: hidden; position: absolute; margin: 0; padding: 0; clip: rect(0, 0, 0, 0); width: 1px; height: 1px; border: 0; }
.bright_radio input[type="checkbox"] + label { padding-left: 34px; line-height: 24px; font-size: 14px; }
.bright_radio input[type="checkbox"] + label:before { content: ''; position: absolute; top: 0; left: 0; width: 24px; height: 24px; border: 1px solid var(--light-Gray02); box-sizing: border-box;}
.bright_radio input[type="checkbox"]:checked  + label:before { border: 1px solid var(--blue02);}
.bright_radio input[type="checkbox"]:checked  + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--blue02); }
.bright_radio input[type="checkbox"]:disabled + label:before { border: 1px solid var(--light-Gray02); }
.bright_radio input[type="checkbox"]:disabled + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--light-Gray02); border-radius: 12px; }
</style>